/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/furniture/Layout/Layout';
import CollectionList from '../../components/collections/CollectionList/CollectionList';
import generateTitle from '../../utils/generateTitle';

const TagTemplate = ({ data, location }) => {
  const {
    detail: { data: tag }
  } = data;

  const pageTitle = generateTitle(tag.title, 'Tags');

  return (
      <Layout location={location} pageTitle={pageTitle}>
        <h1>Tagged with {tag.title}</h1>
        <CollectionList collections={tag.collections} section="collections" />
      </Layout>
  );
};

export const pageQuery = graphql`
    query CollectionByTag($slug: String) {
        detail: airtable(data: { slug: { eq: $slug } }) {
            data {
                title
                slug
                collections {
                    id
                    data {
                        title
                        slug
                        image {
                            localFiles {
                                childImageSharp {
                                    fluid(maxWidth: 600, quality: 40) {
                                        ...GatsbyImageSharpFluid_noBase64
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default TagTemplate;
